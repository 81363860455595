/**
 * Icons Font
 */
@font-face {
    font-family: "icomoon";
    src:
        url("../Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}



/**
 * Main Styles
 */
html, body {
    min-height: var(--full-height);
    overscroll-behavior-y: none;
}
body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-color);
}
#root {
    font-family: var(--main-font);
    font-size: var(--font-size);
    color: var(--font-color);
    background-color: var(--background-color);
}

.left-space.left-space {
    margin-left: 12px;
}
.left-space-big.left-space-big {
    margin-left: 24px;
}
.right-space.right-space {
    margin-right: 8px;
}
.bottom-space.bottom-space {
    margin-bottom: 8px;
}
.top-space.top-space {
    margin-top: 8px;
}
.top-space-big.top-space-big {
    margin-top: 16px;
}



/**
 * Results
 */
.text-green.text-green.text-green {
    color: var(--success-color);
}
.text-yellow.text-yellow.text-yellow {
    color: var(--warning-color);
}
.text-red.text-red.text-red {
    color: var(--error-color);
}



/**
 * Google Map
 */
.map-marker {
    position: absolute;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: translate(-50%, calc(-100% + 4px));
    border-radius: 100%;
    border: 2px solid #424242;
    background-position: center;
    background-size: cover;
}
.map-marker::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    width: 0;
    transform: translateX(-50%);
    border-width: 7px 4px 0;
    border-style: solid;
    border-color: #424242 transparent;
}

.map-info {
    display: flex;
    align-items: center;
    gap: 8px;
}
.map-info img {
    width: 30px;
}



/**
 * Scrollbars
 */
:not(.no-scrollbars)::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
:not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 999px;
    box-shadow: inset 0 0 0 4px var(--border-color-light);
    transition: box-shadow 0.2s;
}
:not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 0 4px var(--border-color-medium);
    cursor: pointer;
}
:not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:vertical {
    border-left-width: 3px;
    min-height: 20px;
}
:not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:horizontal {
    border-top-width: 3px;
    min-width: 20px;
}



/**
 * Font
 */
body,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea,
button {
    font-family: var(--main-font);
}
